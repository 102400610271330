<template>
  <div>
    <h3>Catalogs</h3>
    <p class="text-right">


      <v-btn  dark rounded color=" lighten-2"  class="mx-2" depressed>
        <a href="#" @click="openDialog" class="text-white" >
          Add Root Catalog
        </a>
      </v-btn>
<!--      <span class=" btn btn-primary " text >-->
<!--      <i class="fas fa-plus"></i> Add Root Catalog-->
<!--    </span>-->
    </p>
    <v-app>
      <div class="row">
        <div class="col-xl-12">
          <KTPortlet>
            <template v-slot:body>
              <div class="row" v-if="loading">
                <div class="col-md-12">
                  <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
              </div>
              <v-row v-else-if="!loading && catalogroots.length<1">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                    <h5 class="mt-10">Looks like you have not added any data here. Start by adding a new root category.</h5>
                  </div>
                </div>
              </v-row>
              <div class="row" v-else>
                <div class="col-md-4" v-if="catlogList.length>0">
                  <h5>Categories</h5>
                  <p v-if="catlogList.length>0" class="text-right bg-light">
                      <b> </b> <v-btn href="#" :disabled="!selectedRootId" class=" " text @click="enableCatalog">
                      <i class="fas fa-check"></i>
                      </v-btn>
                      <v-btn href="#" :disabled="!selectedRootId" class="" text @click="disableCatalog">
                      <i class="fas fa-ban"></i>
                      </v-btn>
                      <v-btn href="#" :disabled="!selectedRootId" class=" text-black" text @click="deleteCatalog">
                      <i class="fas fa-trash"></i>
                      </v-btn>
                    </p>
                  <v-treeview
                      :selection-type="'independent'"
                      selectable
                      return-object
                      open-all
                      item-text="label"
                      v-model="selected_catalog"
                      :items="catlogList"
                  >
                    <template v-slot:label="{ item }">
                      <span class="subtitle"> {{ item.title }} </span> <span
                        class="caption"> (ID: {{ item.id }})</span>
                    </template>
                    <template v-slot:prepend="{ item }">
                      <v-icon v-if="item.is_active" x-small color="green darken-2">fa fa-circle</v-icon>
                      <v-icon v-else x-small  color="red darken-2">fa fa-circle</v-icon>
                    </template>
                    <template v-slot:append="{ item }">
                      <a href class="mx-2" @click.prevent="editSelectedItem(item)">
                        <v-icon small color=" darken-2">kt-nav__link-icon flaticon-edit</v-icon>
                      </a>
                      <!-- <a href>
                                              <v-icon large color="red darken-2">kt-nav__link-icon flaticon-delete</v-icon>
                      </a>-->
                    </template>
                  </v-treeview>
                </div>
                <div class="col-md-8">
                  <v-row v-if="catalogroots.length>0">
                    <div v-if="selectedItem==null" class="col-md-12">
                      <v-col cols="12">
                        <v-select
                            :items="catalogroots"
                            v-model="selectedRootId"
                            item-text="title"
                            item-value="id"
                            outlined
                            @change="changeCatalogs(`${selectedRootId.id}`)"
                            return-object
                            dense
                            label="Select Root"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-if="selectedRootId">
                        <h5>Add Category</h5>
                        <v-form>
                          <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="catalog.title"
                                label="Category Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <cascader
                                style="width:100%;"
                                size="large"
                                :options="catlogList"
                                v-model="selected"
                                :fieldNames="{
                                  label: 'title', value: 'id', children: 'children'
                                  }"
                                placeholder="Please select Categroy"
                                change-on-select
                            />
                          </v-col>

                          <v-col cols="12" md="12">
                            <v-textarea
                                outlined
                                dense
                                name="input-7-1"
                                v-model="catalog.description"
                                label="Description"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-file-input
                                v-model="catalog.featured_image"
                                prepend-inner-icon="mdi-camera"
                                prepend-icon=""
                                outlined
                                dense
                                label="Category Image"
                            ></v-file-input>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="catalog.seo_title"
                                label="SEO Title"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-combobox
                                outlined
                                dense
                                v-model="catalog.seo_keyword"
                                label="SEO Keyword"
                                multiple
                                chips
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                                outlined
                                dense
                                name="input-7-1"
                                v-model="catalog.seo_description"
                                label="SEO Description"
                                value
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-btn
                                :loading="isLoading"
                                color="btn btn-primary elevation-2 v-size--large primary"
                                class="btn btn-primary text-white pull-right"
                                text
                                @click.prevent="addCatalogs"
                            >Add
                            </v-btn>
                          </v-col>
                        </v-form>
                      </v-col>
                      <v-col v-else>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <h5 class="mt-10">Please select a catalog root to edit.</h5>
                          </div>
                        </div>
                      </v-col>
                    </div>
                    <div v-else class="col-md-12">
                      <!-- <pre>{{selectedItem}}</pre> -->
                      Edit
                      <v-col cols="12">
                        <v-select
                            :items="catalogroots"
                            v-model="selectedItem.root_id"
                            item-text="title"
                            item-value="id"
                            outlined
                            return-object
                            dense
                            label="Select Root"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-if="selectedRootId">
                        <h5>Add Category</h5>
                        <v-form>
                          <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="selectedItem.title"
                                label="Category Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <cascader
                                style="width:100%;"
                                size="large"
                                :options="catlogList"
                                v-model="selected"
                                          :fieldNames="{
                              label: 'title', value: 'id', children: 'children'
                              }"
                                          placeholder="Please select Category"
                                change-on-select
                            />
                          </v-col>

                          <v-col cols="12" md="12">
                            <v-textarea
                                outlined
                                dense
                                name="input-7-1"
                                v-model="selectedItem.description"
                                label="Description"
                                value
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-file-input
                                v-model="selectedItem.featured_image"
                                outlined
                                dense
                                label="Featured Image"
                            ></v-file-input>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="selectedItem.seo_title"
                                label="Seo Title"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-combobox
                                outlined
                                dense
                                v-model="selectedItem.seo_keyword"
                                label="Seo Keyword"
                                multiple
                                chips
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                                outlined
                                dense
                                name="input-7-1"
                                v-model="selectedItem.seo_description"
                                label="Seo Description"
                                value
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-btn
                                color="red v-btn theme--light elevation-2 v-size--large primary"
                                text
                                class="pull-right"
                                @click.prevent="selectedItem =null"
                            >Cancel
                            </v-btn>
                            <v-btn
                                :loading="isLoading"
                                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                class="btn-save-popup pull-right"
                                text
                                @click.prevent="updateCatalogs"
                            >Update
                            </v-btn>
                          </v-col>
                        </v-form>
                      </v-col>
                      <v-col v-else>
                        <p class="">Select Root To add Category</p>
                      </v-col>
                    </div>
                  </v-row>
                </div>
              </div>
            </template>
          </KTPortlet>
        </div>
      </div>
      <v-dialog scrollable v-model="addRootDialog" max-width="800">
        <v-card>
          <v-card-title class="">Add Root Catalog</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field outlined dense v-model="catalogRoot.title" label="Root Catalog Name"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="text-black" text @click="addRootDialog = false">cancel</v-btn>

            <v-btn color="text-white" class="btn btn-save-popup" text @click="createRoot">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import {required} from "vuelidate/lib/validators";
import Cascader from "ant-design-vue/lib/cascader";
import "ant-design-vue/dist/antd.css";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
import ProductCatalogService from "@/services/store/ProductCatalogService";
const ProductRoot = new ProductCatalogRootService();
const ProductCatalog = new ProductCatalogService();
export default {
  name: "store-catlogs",
  components: {
    KTPortlet,
    Cascader
  },
  validations: {
    catlog: {
      title: {required},
      custom_slug: {required}
    }
  },
  data: () => ({
    isLoading: false,
    loading: true,
    selected: null,
    addRootDialog: false,
    catalogroots: [],
    catalogRoot: {
      title: null,
      is_active: 1
    },
    selectedItem: null,
    catalog: {
      title: "",
      is_parent: 1,
      parent_id: null,
      description: null,
      position: null,
      is_active: 1,
      seo_title: "",
      seo_description: "",
      seo_keyword: "",
      featured_image: null
    },
    selected_catalog: null,
    selectedRootId: null,
    options: [],
    itemss: ["Foo", "Bar", "Fizz", "Buzz"],
    items: [],
    catlogList: []
  }),

  methods: {
    openDialog() {
      this.addRootDialog = true;
    },
    closeDialog() {
      this.addRootDialog = false;
    },
    getCatalogsRoots() {
      ProductRoot.paginate().then(res => {
        this.catalogroots = res.data;
        this.loading = false;
      });
    },
    createRoot() {
      ProductRoot.create(this.catalogRoot).then(res => {
        this.$snotify.success("Catalog Root Added Successfully");
        this.getCatalogsRoots();
        this.resetCatalogRoot();
        this.closeDialog();
      });
    },
    addCatalogs() {
      this.isLoading = true;
      let data = this.catalog;
      data.parent_id =
          this.selected && this.selected.length > 0
              ? this.selected[this.selected.length - 1]
              : null;

      let fd = new FormData();
      for (var key in data) {
        if (
            key === "featured_image" &&
            (data["featured_image"] == null ||
                data["featured_image"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "parent_id" &&
            (data["parent_id"] == null || data["parent_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, data[key]);
        }

      }
      ProductCatalog.create(this.selectedRootId.id, fd).then(res => {
        this.isLoading = false;
        this.$snotify.success("Catalog  Added Successfully");
        this.resetCatalog();
        this.resetCatalogRoot();
      });
    },
    updateCatalogs() {
      this.isLoading = true;
      let data = this.selectedItem;
      data.parent_id =
          this.selected && this.selected.length > 0
              ? this.selected[this.selected.length - 1]
              : null;

      let fd = new FormData();
      for (var key in data) {
        if (
            key === "featured_image" &&
            (data["featured_image"] == null ||
                data["featured_image"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "parent_id" &&
            (data["parent_id"] == null || data["parent_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, !data[key] ? "" : data[key]);
        }
      }
      ProductCatalog.update(
          this.selectedItem.root_id,
          this.selectedItem.id,
          fd
      ).then(res => {
        this.$snotify.success("Catalog  Updated Successfully");
        this.resetCatalog();
        this.resetCatalogRoot();
      });
    },
    editSelectedItem(item) {
      this.selectedItem = item;
    },
    changeCatalogs(id) {
      this.getCatalogs(id);
    },
    getCatalogs(id = null) {
      if (id) {
        ProductCatalog.getCatlogByRootId(id).then(res => {
          this.catlogList = res.data;
          this.loading = false;
        });
      }
    },
    resetCatalogRoot() {
      this.catalogRoot = {title: null, is_active: 1};
    },
    deleteCatalog() {
      this.$confirm({
        message: `Are you sure you want to delete?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let catalogIds = [];
            if (
                this.selected_catalog != null &&
                this.selected_catalog != undefined
            ) {
              this.selected_catalog.forEach(element => {
                catalogIds.push(element);
              });
            }
            if (catalogIds.length > 0) {
              ProductCatalog.delete(this.selectedRootId.id, catalogIds)
                  .then(res => {
                    this.$snotify.success("Catalog  deleted successfully");
                    this.getCatalogsRoots();
                    this.resetCatalogRoot();
                    this.getCatalogs(this.selectedRootId.id);
                  })
                  .catch(error => {
                  });
            }
          }
        }
      });
    },
    resetCatalog() {
      this.catalog = {
        description: null,
        featured_image: null,
        is_active: 1,
        is_parent: null,
        parent_id: null,
        position: null,
        seo_description: null,
        seo_keyword: null,
        seo_title: null,
        title: ""
      };
      this.isLoading = false;
      this.getCatalogsRoots();
      this.getCatalogs(this.selectedRootId.id);
    },
    enableCatalog() {
      let catalogIds = [];
      if (this.selected_catalog != null && this.selected_catalog != undefined) {
        this.selected_catalog.forEach(element => {
          catalogIds.push(element);
        });
      }
      if (catalogIds.length > 0) {
        ProductCatalog.enableCatalog(this.selectedRootId.id, catalogIds)
            .then(res => {
              this.$snotify.success("Catalog  enabled successfully");
              this.getCatalogsRoots();
              this.resetCatalogRoot();
              this.getCatalogs(this.selectedRootId.id);
            })
            .catch(error => {
            });
      }
    },
    disableCatalog() {
      let catalogIds = [];
      if (this.selected_catalog != null && this.selected_catalog != undefined) {
        this.selected_catalog.forEach(element => {
          catalogIds.push(element);
        });
      }
      if (catalogIds.length > 0) {
        ProductCatalog.disableCatalog(this.selectedRootId.id, catalogIds)
            .then(res => {
              this.$snotify.success("Catalog  enabled successfully");
              this.getCatalogsRoots();
              this.resetCatalogRoot();
              this.getCatalogs(this.selectedRootId.id);
            })
            .catch(error => {
            });
      }
    }
  },
  mounted() {
    this.getCatalogsRoots();
    this.getCatalogs();
  }
};
</script>
